import React from "react";

function Home() {
	return (
		<div id={'main'}>
			<p>Home</p>
		</div>
	);
}

export default Home;